<template>
  <div class="game-item">
    <NuxtLink :to="game.url" class="item hover-shine">
      <BaseImg class="img-game" :src="game.objectImg" />
    </NuxtLink>
    <div v-if="sumJackpot[game.jackpot] > 0" class="jackpot">
      <AnimateNumber :number="sumJackpot[game.jackpot]" :show-coin="true" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app'
import { useJackpot } from '~/composables/game/useJackpot'
import AnimateNumber from '~/components/common/animate-number.vue'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { currentUser, sumJackpot, jackpot } = storeToRefs(store)
const router = useRouter()

const props = defineProps({
  game: {
    type: Object,
    default: () => {}
  },
  index: {
    type: Number,
    default: 0
  }
})

</script>
<style lang="scss" scoped src="~/assets/scss/components/mobile/pages/home/game-center/item.scss"></style>
