<template>
  <div class="support-container">
    <span class="title">Liên hệ</span>
    <div class="support">
      <div
        v-for="item in contacts"
        :id="`support-${item?.name}`"
        :key="item.name"
        class="support__item"
        :class="[item?.type, item?.className]"
      >
        <div
          v-if="item.isLiveChat"
          class="support__item--link"
          @click="$openLiveChat()"
        >
          <CommonBaseImg
            v-if="!isBotChromeLighthouse"
            class="icon icon-guide"
            :src="item.img"
            :alt="item.name"
          />
          <div class="text">{{ item.content }}</div>
        </div>
        <div v-else>
          <NuxtLink
            :to="item.url"
            target="_blank"
            class="support__item--link"
          >
            <CommonBaseImg
              v-if="!isBotChromeLighthouse"
              class="icon"
              :src="item.img"
              :alt="item.name"
            />
            <p class="text">{{ item.content }}</p>
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useAppStore } from '~/store/app'
import { getFooterContact } from '@/constants/footer'
import { useContact } from '~/composables/useContact'
const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))
const { isBotChromeLighthouse } = useAppStore()
const { SUPPORT_MENU } = getFooterContact()
const { fetchBranchContact, contacts } = useContact()

onMounted(async () => {
  await fetchBranchContact()
})
</script>
<style lang="scss" scoped src="assets/scss/components/mobile/pages/home/section-contact/index.scss" />
