import { LOBBY_CASINO_URLS, LOBBY_GAME_URLS, PAGE_URLS } from '~/config/page-url'

export const gameCenters = [
  {
    url: PAGE_URLS.SPORT,
    text: 'Thể thao',
    objectImg: '/assets/images/components/desktop/pages/home/games/the-thao.png',
    tag: 'assets/images/components/desktop/pages/home/games/tag-hot.png'
  },
  {
    url: LOBBY_GAME_URLS.TABLE_GAME,
    text: 'Table Games',
    objectImg: '/assets/images/components/desktop/pages/home/games/table-game.png'
  },
  {
    url: LOBBY_GAME_URLS.LO_DE,
    text: 'Lô Đề',
    objectImg: '/assets/images/components/desktop/pages/home/games/lode.png'
  },
  {
    url: LOBBY_GAME_URLS.SLOTS_GAME,
    text: 'Slots',
    objectImg: '/assets/images/components/desktop/pages/home/games/slots.png'
  },
  {
    url: LOBBY_GAME_URLS.NO_HU,
    text: 'Nổ Hũ',
    objectImg: '/assets/images/components/desktop/pages/home/games/nohu.png',
    jackpot: 'jackpotNohu'
  },
  {
    url: LOBBY_GAME_URLS.GAME_BAI,
    text: 'Game Bài',
    objectImg: '/assets/images/components/desktop/pages/home/games/game-bai.png'
  },
  {
    url: LOBBY_GAME_URLS.QUAY_SO,
    text: 'Quay Số',
    objectImg: '/assets/images/components/desktop/pages/home/games/quayso.png'
  },
  {
    url: LOBBY_CASINO_URLS.ALL_CASINO,
    text: 'Live Casino',
    objectImg: '/assets/images/components/desktop/pages/home/games/livecasino.png'
  },
  {
    url: LOBBY_GAME_URLS.BAN_CA,
    text: 'Bắn Cá',
    objectImg: '/assets/images/components/desktop/pages/home/games/ban-ca.png',
    jackpot: 'jackpotFishing'
  },
  {
    url: LOBBY_GAME_URLS.QUICK_GAMES,
    text: 'Game Nhanh',
    objectImg: '/assets/images/components/desktop/pages/home/games/game-nhanh.png'
  }
]
