<template>
  <div class="live-casino">
    <HeaderSection
      title="Live Casino"
      :url-section="`${PAGE_URLS.CASINO}`"
      text-url="Xem Thêm"
      :is-hide-light-arrow="false"
    />
    <div class="frame">
      <div class="background-image" />
    </div>
    <div class="swiper-container">
      <Swiper v-bind="swiperOption" class="swiper" @swiper="onSwiper" @slide-change="handleSlideChange">
        <SwiperSlide v-for="(item, index) in liveCasinoList" :key="index">
          <nuxt-link :to="item.link" class="slider-item">
            <BaseImg :src="item.image" :alt="item.alt" />
          </nuxt-link>
        </SwiperSlide>
      </Swiper>
      <!-- Custom Pagination -->
      <div class="custom-pagination">
        <span
          v-for="(_, index) in paginationDots"
          :key="index"
          class="pagination-dot"
          :class="{ 'active': index === currentSlideIndex }"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { EffectCoverflow } from 'swiper'
import { LIVE_CASINOES } from '@/resources/live-casino'
import HeaderSection from '~/components/mobile/pages/home/livecasino/header-section.vue'
import { PAGE_URLS } from '~/config/page-url'

interface CasinoItem {
  link: string
  image: string
  alt: string
}

const liveCasinoList = ref<CasinoItem[]>(LIVE_CASINOES)

const swiperInstance = ref(null)
const currentSlideIndex = ref(1) // Assuming initialSlide is 1

const swiperOption = reactive({
  slidesPerView: 3,
  slidesPerGroup: 1,
  spaceBetween: 8,
  centeredSlides: true,
  initialSlide: 1,
  loop: true,
  pagination: false,
  modules: [EffectCoverflow]
})

const onSwiper = (swiper) => {
  swiperInstance.value = swiper
}

const paginationDots = computed(() => {
  return new Array(liveCasinoList.value.length).fill(null)
})

const handleSlideChange = (swiper: any) => {
  if (swiperInstance.value) {
    currentSlideIndex.value = swiperInstance.value.realIndex
  }
  const slides = swiper.slides
  const activeIndex = swiper.activeIndex % slides.length
  slides.forEach((slide: any, index: number) => {
    slide.style.transform = 'scale(0.9)'
    if (index === activeIndex) {
      slide.style.transform = 'scale(1.1)'
    } else if (
      index === (activeIndex - 1 + slides.length) % slides.length ||
      index === (activeIndex + 1) % slides.length
    ) {
      slide.style.transform = 'scale(0.9)'
    } else {
      slide.style.transform = 'scale(0.9)'
    }
  })
}

onMounted(() => {
  const swiperInstance = document?.querySelector('.swiper')?.swiper
  handleSlideChange(swiperInstance)
})
</script>

<style lang="scss" scoped src="assets/scss/components/mobile/pages/home/live-casino/index.scss"></style>
