<template>
  <div class="hot-games">
    <div class="header-section container-custom">
      <div class="header-section__text">
        <div class="header-section__title">
          Nhiều Người Xem
        </div>
      </div>
    </div>
    <div class="swiper-container container-custom">
      <div class="jackpot-swiper-button-next1" />
      <div class="jackpot-swiper-button-prev1" />
      <Swiper v-bind="swiperOption" class="swiper">
        <SwiperSlide v-for="(item, index) in listGame" :key="index">
          <SlotGameItem :item="item" :show-coin="true" />
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Autoplay } from 'swiper'
import { storeToRefs } from 'pinia'
import SlotGameItem from '~/components/common/game-item.vue'
import { useGame } from '~/composables/game/useGame'
import { useJackpot } from '~/composables/game/useJackpot'
import { useAppStore } from '~/store/app'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot } = storeToRefs(store)
const jackpotNumber: Ref<number | null> = ref(null)

const { onJackpotInterval, onJackpotIntervalClear, onSumJackpotInterval, onSumJackpotIntervalClear } = useJackpot()

const { listGame, fetchTopGames } = useGame()

onBeforeUnmount(() => {
  onJackpotIntervalClear()
  onSumJackpotIntervalClear()
})

const swiperOption = reactive({
  slidesPerView: 6,
  slidesPerGroup: 6,
  initialSlide: 7,
  spaceBetween: 24,
  loop: true,
  navigation: {
    nextEl: '.jackpot-swiper-button-next1',
    prevEl: '.jackpot-swiper-button-prev1'
  },
  modules: [Navigation]
})

onMounted(() => {
  onJackpotInterval()
  onSumJackpotInterval()
  jackpotNumber.value = sumJackpot.value?.jackpotNohu ?? null
})
useAsyncData(async () => {
  await fetchTopGames()
})
</script>

<style lang="scss" scoped src="assets/scss/components/desktop/pages/home/hot-games.scss"></style>
